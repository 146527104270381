export const MAP = {
  name: "nebraska-regions",
  areas: [
    {
      name: "Panhandle",
      shape: "circle",
      coords: [124, 48, 20],
      preFillColor: "clear",
    },
    {
      name: "Sandhills",
      shape: "circle",
      coords: [254, 62, 20],
      preFillColor: "clear",
    },
    {
      name: "South West",
      shape: "circle",
      coords: [224, 198, 20],
      preFillColor: "clear",
    },
    {
      name: "South Central",
      shape: "circle",
      coords: [374, 238, 20],
      preFillColor: "clear",
    },
    {
      name: "North East",
      shape: "circle",
      coords: [454, 68, 20],
      preFillColor: "clear",
    },
    {
      name: "South East",
      shape: "circle",
      coords: [544, 204, 20],
      preFillColor: "clear",
    },
    {
      name: "Metro",
      shape: "circle",
      coords: [610, 175, 20],
      preFillColor: "clear",
    },
  ],
};
